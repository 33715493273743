import React from "react"
import { handleAuthentication } from "../utils/auth"

import Layout from "../components/layout"

const Callback = () => {
    handleAuthentication()

    return(
        <Layout>
            <div className="container">
                <p>Loading...</p>
            </div>
        </Layout>
    )
}
export default Callback